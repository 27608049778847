import React from "react";
import "../assets/css/footer.css";
import footer_logo from "../assets/images/Aurm_Logo.svg";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
const react_app_main_site_url = process.env.REACT_APP_MAIN_SITE_URL;
const react_app_blog_url = process.env.REACT_APP_BLOGS_URL;

/**
 * Shows the Footer of the Website.
 */
function FooterComponent() {
  // Scrolls to the section.
  const scrollToSection = (sectionName) => {
    const scroller_section_tag = document.getElementById(sectionName);
    if (scroller_section_tag) {
      scroller.scrollTo(sectionName, {
        duration: 50,
        smooth: true,
      });
    }
  };
  return (
    <div
      className="footer m-auto d-flex flex-column justify-content-start align-items-center footer_text_color"
      id="footer_section"
    >
      <div className="footer_container d-flex flex-column justify-content-start align-items-center">
        <div className="footer_logo_div">
          <a href="/">
            <img
              src={footer_logo}
              alt="aurm"
              className="footer_logo w-100 h-100"
              loading="lazy"
            />
          </a>
        </div>
        <div className="menu_container d-flex justify-content-center align-items-center flex_wrap_options">
          <div className="menu_item footer_text_gradient_color">
            <a href={`${react_app_main_site_url}`} target="_blank" rel="noopener noreferrer">
              <div className="menu_text">Home</div>
            </a>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <a href={`${react_app_main_site_url}/our_story`} target="_blank" rel="noopener noreferrer">
              <div className="menu_text">Our Story</div>
            </a>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <a href={`${react_app_blog_url}`} target="_blank" rel="noopener noreferrer">
              <div className="menu_text">Blog</div>
            </a>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <Link to="/privacy-policy" 
            onClick={() => {
              scrollToSection("policy_section");
            }}
            className="link_no_display">
              <div className="menu_text">Privacy</div>
            </Link>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <Link to="/terms-and-conditions" 
            onClick={() => {
              scrollToSection("policy_section");
            }}
            className="link_no_display">
              <div className="menu_text">Terms and Conditions</div>
            </Link>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <Link to="/refund-policy" 
            onClick={() => {
              scrollToSection("policy_section");
            }}
             className="link_no_display">
              <div className="menu_text">Refund Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer_bottom justify-content-center align-items-center">
        <div className="copyright footer_link footer_text_gradient_color">© 2024 Pransu Technologies Pvt. Ltd. All rights reserved. An ISO 9001:2015 and ISO/IEC 27001:2022 Certified Company</div>
        <div className="footer_links d-flex flex-row justify-content-center align-items-center d-none">
        <a  href="https://aurm.in/terms-and-conditions" target="_blank" rel="noopener noreferrer"> <div className="footer_link footer_text_gradient_color">Terms</div></a>
        <a href="https://aurm.in/privacy-policy" target="_blank" rel="noopener noreferrer">
            <div className="footer_link footer_text_gradient_color">Privacy Policy</div>
        </a>
          <div className="footer_link footer_text_gradient_color">Cookies</div>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;
