import React, { useEffect } from "react";
import Header from "./HeaderComponent";
import "../assets/css/privacy_and_refund.css";
import FooterComponent from "./FooterComponent";
import KeyHighlightsComponent from "./KeyHighlightsComponent";

/**
  Shows Privacy and Refund Policy page
*/
function PrivacyAndRefundComponent({ flag }) {
  useEffect(() => {
    const scroll_to = document.getElementById("policy_section");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <section>
      <Header />
      <div id="policy_section">
        <div className="container">
          <div className="row">
            <div className="container" id="padding_privacy_id">
              <div id="policy_header"></div>
              <div className="container" id="privacy_and_refund_container">
                <div
                  className="text-center px-2 policy_div"
                  id="privacy_policy_head"
                >
                  {flag ? (
                    <>
                      <div id="privacy_policy_main_head">Privacy Policy</div>
                      <div id="policy_intro">
                        At Aurm, we value your privacy and are committed to
                        safeguarding your personal information. This{" "}
                        <span>
                          <a
                            href="#privacy_details_info"
                            className="light_red_color under_lined_text"
                          >
                            privacy policy
                          </a>
                        </span>{" "}
                        protection outlines how we collect, use, and protect
                        your data
                      </div>
                    </>
                  ) : (
                    <>
                      <section id="refund_policy_main_section">
                        <div id="privacy_policy_main_head">Refund Policy</div>
                        <div id="policy_intro">
                          At Aurm we ensure a transparent and fair process for
                          handling refunds
                        </div>
                      </section>
                    </>
                  )}
                </div>
              </div>
              <div id="privacy_container_intro">
                <div className="text-left policy_div">
                  {flag ? (
                    <>
                      <KeyHighlightsComponent />
                      <div
                        className="policy_section_sub_heads"
                        id="privacy_details_info"
                      >
                        What information do we collect and what do we use the
                        information for?{" "}
                      </div>
                      <div className="policy_information">
                        We may collect your title, name, email address, postal
                        address, telephone number, mobile number, birthdate,
                        nationality, identification documents, biometric
                        information, face ID, Credit Rating and any other
                        information you provide to us in writing or through the
                        Internet while registering with us. The Category of
                        information collected by Us can be:
                      </div>
                      <div className="policy_information">
                        <ul className="list_of_privacy_policy_data">
                          <li>
                            Identity and Account Opening Information (e.g. name,
                            address, date of birth)
                          </li>
                          <li>
                            Contact Information (e.g. mobile number, permanent
                            address)
                          </li>
                          <li>
                            Financial Information (e.g. Bank account number,
                            credit score, etc.)
                          </li>
                          <li>
                            Identification Information (e.g. KYCs, PAN number)
                          </li>
                          <li>
                            Usage and Technical Information (e.g. IP address)
                          </li>
                          <li>
                            Marketing and Communication Information (e.g.
                            customer support logs)
                          </li>
                          <li>
                            Information in your device(s) (e.g. camera, OS,
                            etc.)
                          </li>
                        </ul>
                      </div>
                      <div className="policy_information">
                        We receive and store any information you enter on our
                        web site or give us in any other way. We maintain and
                        use your personal data to serve your safe deposit locker
                        and deliver better services to you and to protect
                        against fraud and also to send you communications
                        containing information and updates about our products,
                        services, offers, promotions and special events, and
                        other marketing communications that may be of interest
                        to you.
                      </div>
                      <div className="policy_information">
                        Further You authorize the Company to share with Credit
                        Information Companies (CIC), my personal
                        information/details to procure my Credit Information to
                        use the same to (a) provide me access to my Credit
                        Information and Credit Score (including any changes
                        therein) on the platform; (b) provide me with such
                        products / services that I may be interested in and
                        choose to avail; and/or (c) take such other actions as
                        may be required in relation to the above.
                      </div>
                      <div className="policy_information">
                        We use the information we collect about you to improve
                        the content of our website, products and services, to
                        respond to your inquiries and feedback and to provide
                        you with a better personalized experience. We may also
                        use the information to notify you about important
                        functionality changes to the website and new services we
                        think you’ll find valuable. We will retain and use the
                        information only for the purposes as set out herein and
                        no longer than is reasonably required for that purpose.
                      </div>
                      <div className="policy_information">
                        While you have the option not to provide us with certain
                        information or withdraw consent to collect certain
                        information, kindly note that in such an event you may
                        not be able to take full advantage of the entire scope
                        of features and services offered to you and we reserve
                        the right not to provide you with our services.
                      </div>
                      <div className="policy_information">
                        By using our services, you hereby expressly agree and
                        grant consent to the collection, use and storage of this
                        information by us. Information about our customers is an
                        important part of our business and we are not in the
                        business of selling it to others. We reserve the right
                        to share, disclose and transfer information collected
                        hereunder with our own affiliates. In the event, we sell
                        or transfer all or a portion of our business assets,
                        consumer information may be one of the business assets
                        that are shared, disclosed or transferred as part of the
                        transaction. You hereby expressly grant consent and
                        permission to us for disclosure and transfer of
                        information to such third parties. You authorize us to
                        exchange, share, part with all information related to
                        the details and transaction history of you to our
                        affiliates / banks / financial institutions / agencies/
                        participation in any telecommunication or electronic
                        clearing network as may be required by law, customary
                        practice, statistical analysis, verification or risk
                        management and shall not hold us liable for use or
                        disclosure of this information.
                      </div>
                      <div className="policy_information">
                        Other than as set out herein, you will receive notice
                        when information about you might go to third parties and
                        you will have an opportunity to choose not to share the
                        information. Further, we reserve the right to disclose
                        personal information as obligated by law, in response to
                        duly authorized legal process, governmental requests and
                        as necessary to protect our rights and interests and
                        enforce the terms and conditions of the products or
                        services. If you decide to surrender your locker or
                        become an inactive customer, we will continue to follow
                        our privacy policy with the information we have about
                        you. Any changes to our privacy policy will be posted on
                        this page, so you are always aware of what information
                        we collect, how we use it, how we store it and under
                        what circumstances we disclose it.
                      </div>
                      <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                        Third Party Links
                      </div>
                      <div className="policy_information">
                        Occasionally, at our discretion, we may include or offer
                        third party products or services on our website. These
                        third-party sites have separate and independent privacy
                        policies. We therefore have no responsibility or
                        liability for the content and activities of these linked
                        sites. Nonetheless, we seek to protect the integrity of
                        our site and welcome any feedback about these sites. For
                        your convenience, our website may contain links to other
                        sites. We are not responsible for the privacy practices
                        or the content of such websites.
                      </div>
                      <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                        Do we use cookies?
                      </div>
                      <div className="policy_information">
                        We use cookies placed on your computer’s hard drive
                        through your Web browser to enable our systems to
                        recognize your browser during your visit to our site or
                        app and when you return to our site. Whenever you use
                        this website, information may be collected using cookies
                        and other technologies. By using this website or
                        application, you agree to Company's use of cookies as
                        described here. However, you can control and manage
                        cookies in various ways. Please bear in mind that
                        removing or blocking cookies can impact user experience
                        and parts of this website may no longer be fully
                        accessible.
                      </div>
                      <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                        Grievance Officer
                      </div>
                      <div className="policy_information">
                        The grievance officer designated hereunder shall address
                        any grievance that you may have regarding our privacy
                        policy.
                        <br />
                        Name: Data Privacy and Policy Officer <br />
                        E-mail:{" "}
                        <a
                          href="#"
                          className="text-decoration-underline light_red_color"
                        >
                          dppo@aurm.in
                        </a>
                      </div>
                      <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                        Changes to Privacy Policy
                      </div>
                      <div className="policy_information">
                        We may update this Privacy Policy to reflect changes to
                        our information practices. If we make any material
                        changes we will notify you by email (sent to the e-mail
                        address specified in your account) prior to the change
                        becoming effective.
                      </div>
                      <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                        Your Consent
                      </div>
                      <div className="policy_information">
                        By using our site, engaging with our service, you
                        consent to our privacy policy.
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="policy_information">
                        <ul className="list_of_privacy_policy_data">
                          <li className="pb-3">
                            The subscription fee must be paid in advance at the
                            start of the year.
                          </li>
                          <li className="pb-3">
                            No refunds are permitted during the Lock-in Period,
                            depending on the chosen Plan.
                          </li>
                          <li className="pb-3">
                            Upon termination of the Agreement for the assigned
                            Locker before the Term expires, a pro-rated refund
                            of the subscription fee will be issued.
                          </li>
                          <li className="pb-3">
                            The refund amount will be calculated based on the
                            remaining unused portion of the prepaid subscription
                            fee and the security deposit, excluding any
                            applicable fees or charges.
                          </li>
                          <li className="pb-3">
                            The pro-rated refund will be processed within 15
                            business days from the date the termination request
                            is processed. Any outstanding fees or charges will
                            be deducted from the refund amount.
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="privacy_footer" id="privacy_footer">
          <FooterComponent />
        </div>
      </div>
    </section>
  );
}

export default PrivacyAndRefundComponent;
