import React, { useEffect } from "react";
import "../assets/css/global_styles.css";
import "../assets/css/key_highlights.css";

/**
  Terms Key Highlights Component
*/
function TermsKeyHighlightsComponent() {
  useEffect(() => {
    const scroll_to = document.getElementById("blogs_page_div");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <section id="terms_key_section">
      <div className="container p-0">
        <div id="key_header">
          Key Highlights
        </div>
        <div className="row kh_row" id="kh_row_1">
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white mob_first_row_card fs_first_row tb_fs_row">
              <div className="card_custom_header">
                Who can subscribe to an Aurm safe?
              </div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  Aurm safes are exclusively for residents of the community
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white mob_first_row_card fs_first_row tb_fs_row">
              <div className="card_custom_header">
                Things you can store in your safe
              </div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  Jewellery, important documents, certificates, collectibles and
                  other valuable items for personal use
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white fs_first_row tb_sd_row">
              <div className="card_custom_header">
                Giving others access to your safe
              </div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  You can add joint account holders to operate your safe, and a
                  nominee who gets access in case of an unfortunate incident
                  such as your death or disability
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white fs_second_row tb_sd_row">
              <div className="card_custom_header">Accessing your safe</div>
              <div className="card_custom_body">
                <div className="card_custom_text custom_terms_gap">
                  You have unlimited access, 24 hours a day, 365 days a year
                </div>
                <div className="card_custom_text">
                  This is subject to: Clubhouse timing restrictions, or if
                  another user has booked a slot or is accessing their safe, or
                  if there is any maintenance work, or if there are reasons
                  beyond our control, such as a power outage, riot, curfew or
                  lockout
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white fs_second_row tb_td_row">
              <div className="card_custom_header">
                When can your access be restricted
              </div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  The privacy policy may be updated, and will be posted on our
                  website before the changes come into effect. You can review
                  the{" "}
                  <span>
                    <a
                      href="/privacy-policy"
                      className="text-decoration-underline light_red_color"
                    >
                      privacy policy
                    </a>
                  </span>
                  {" "}
                  periodically.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white fs_second_row tb_td_row">
              <div className="card_custom_header">
                Aurm can terminate your subscription if
              </div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  Strong security measures are in place to protect your data.
                  Aurm complies with all data privacy and protection laws
                  including the Digital Personal Data Protection (DPDP) Act
                  2023. We are also ISO 9001:2015 and ISO 27001:2022 compliant.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row kh_row" id="kh_row_2">
          <div className="col-md-6 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white kh_fs_second_row">
              <div className="card_custom_header">
                What happens if your rent is unpaid
              </div>
              <div className="card_custom_body">
                <div className="card_custom_text custom_terms_gap">
                  We will attempt to contact you multiple times once the rent
                  becomes overdue
                </div>
                <div className="card_custom_text custom_terms_gap">
                  A termination notice will be sent to you after 18 months
                </div>
                <div className="card_custom_text custom_terms_gap">
                  If we still don’t hear from you, we will move your safe
                  deposit box to another safe vault in a different location, so
                  that we can allot the vacant space to another community
                  resident
                </div>
                <div className="card_custom_text">
                  Your safe deposit box subscription can be made active again
                  once you clear all the outstanding dues
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white kh_fs_second_row">
              <div className="card_custom_header">
                Things you cannot store in your safe (or any bank locker)
              </div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  Strong security measures are in place to protect your data.
                  Aurm complies with all data privacy and protection laws
                  including the Digital Personal Data Protection (DPDP) Act
                  2023. We are also ISO 9001:2015 and ISO 27001:2022 compliant.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 kh_card_div">
          <div className="card kh_card bg-transparent border-0 text-white fs_card">
            <div className="card_custom_header">
              Your responsibility and Aurm’s liability in case of loss
            </div>
            <div className="card_custom_body">
              <div className="card_custom_text custom_terms_gap">
                In case of any theft or loss due to natural calamities or the
                company’s negligence, Aurm’s liability is limited to the amount
                of insurance coverage or the value of the items lost, whichever
                is lower
              </div>
              <div className="card_custom_text custom_terms_gap">
                You are responsible for locking your safe and ensuring that you
                haven’t left anything outside, in the Aurm access bay or nearby
                areas. In case this happens, we will assist you to the best of
                our abilities to locate and retrieve the items. But Aurm cannot
                be held responsible for the loss of items due to the customer’s
                negligence - such as items that were left behind
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TermsKeyHighlightsComponent;
