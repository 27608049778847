import "../assets/css/book_your_aurm_safe.css";
import aurm_playstore_img from "../assets/images/book_your_aurm_safe_playstore_img.svg"
import aurm_appstore_img from "../assets/images/book_your_aurm_safe_appstore_img.svg"

/*
  Pre book your aurm safe section component.
*/
function BookYOurAurmSafe() {
  return (
    <div className="book_your_aurm_safe_section" id="pre_book">
        <div>
          <div className="book_ypur_aurm_safe_content_container text-center">
            <div className="book_your_aurm_safe_primary_text mt-4 text-white">
              Book your Aurm safe now
            </div>
            <div className="book_your_aurm_safe_secondary_text text-center">
            Download our app
            </div>
            <div className="d-flex justify-content-center mt-3 book_your_aurm_safe_img_div">
            <a href="https://play.google.com/store/apps/details?id=com.aurm.userapp" target="_blank" rel="noreferrer"><img
              className="book_your_aurm_safe_playstore_imgs"
              src={aurm_playstore_img}
              alt="playstore"
              loading="lazy"
            />
            </a>
            <a href="https://apps.apple.com/us/app/aurm/id6451835407" target="_blank" rel="noreferrer">
            <img
              className="book_your_aurm_safe_playstore_imgs"
              src={aurm_appstore_img}
              alt="ios"
              loading="lazy"
            />
            </a>
            </div>
          </div>
          </div>
    </div>
  );
}

export default BookYOurAurmSafe;
