import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeComponent from '../components/HomeComponent';
import PrivacyAndRefundComponent from '../components/PrivacyAndRefundComponent';
import TermsAndConditionsComponent from '../components/TermsandConditionsComponent';

function AppRouter() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route
        path="/privacy-policy"
        element={<PrivacyAndRefundComponent flag={true} />}
      />
      <Route
        path="/refund-policy"
        element={<PrivacyAndRefundComponent flag={false} />}
      />
      <Route path='/terms-and-conditions' element={<TermsAndConditionsComponent />} />
    </Routes>
  </Router>
  );
}

export default AppRouter;
