import React, { useState, useEffect } from 'react';
import "../assets/css/faqs.css";
import faqs_arrow from "../assets/images/faqs-arrow.svg";

/**
    Shows the Faqs section
*/

function FaqsComponent() {
    const [answersVisible, setAnswersVisible] = useState({});
    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://pub-assets-microsite.aurm.in/json/faq_micro.json');
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          setFaqData(data.faqSections);
        } catch (error) {
          console.error('Error fetching FAQ data:', error);
        }
      };
      fetchData();
    }, []);

     const toggleAnswer = (sectionIndex, questionIndex) => {
    setAnswersVisible((prev) => ({
      ...prev,
      [`${sectionIndex}-${questionIndex}`]: !prev[`${sectionIndex}-${questionIndex}`],
    }));
  };

  return (
    <div className='faqs_main_section' id="faq">
      <div className="container">
        <div className="faqs_heading">Frequently Asked Questions</div>
        <div className="faq-container">
          {faqData.map((section, sectionIndex) => (
            <div className="faq_sections_div faq_security_section" key={sectionIndex}>
              <span className="faq_sections_heading">{section.sectionTitle}</span>
              {section.questions.map((question, questionIndex) => (
                <div className="faq-item" key={questionIndex}>
                  <div className="faq-question" onClick={() => toggleAnswer(sectionIndex, questionIndex)}>
                    <span>{question.question}</span>
                    <img
                      className={`faq_arrow ${answersVisible[`${sectionIndex}-${questionIndex}`] ? 'up' : 'down'}`}
                      src={faqs_arrow}
                      alt="Arrow"
                    />
                  </div>
                  {answersVisible[`${sectionIndex}-${questionIndex}`] && (
                    <div className="faq-answer">
                    <p dangerouslySetInnerHTML={{__html:question.answer}}></p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FaqsComponent;



