import React from "react";
import "../assets/css/features.css";
import dots from "../assets/images/dots.svg";


/**
  Features Component lists the important features
*/
function FeaturesComponent() {
  
  return (
    <div id="feature_section">
      <div className="features_div container">
        <div className="dots_image text-left ps-3" id="feature_top_dots_img">
          <img src={dots} alt="dots" id="feature_dots_img_tag" />
        </div>
        <div className="feature_header text-center">
          It’s not just a safe, it’s <br />
          <span id="feature_header_sub_text">Peace of mind</span>
          <div className="feature_sub_text m-auto">
            Introducing Aurm secure vaults. Now you can safeguard your valuables
            in your own gated community, and access them anytime. We understand
            what you really aspire for - Peace Of Mind.
          </div>
        </div>
        <div className="col-md-12">
          <div className="dots_image text-end" id="card_back_dots">
            <img src={dots} alt="dots" />
          </div>
          <div className="row justify-content-center cards_row">
            <div className="col-md-4 feature_card">
              <div className="card text-center bg-transparent border-0 position-relative">
                <video className="card-img-top" muted autoPlay loop playsInline poster="https://pub-assets.aurm.in/security_logo.webp" loading="lazy">
                <source src="https://pub-assets.aurm.in/videos/Feature_Animation_01.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
                <div className="card_body">
                  <div className="card_header">Avant-Garde Security</div>
                  <div className="card_sub_text">
                    Military grade strong rooms with state-of-the-art
                    surveillance and intrusion-proof technologies.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 feature_card">
              <div className="card text-center bg-transparent border-0">
              <video className="card-img-top" muted autoPlay loop playsInline poster="https://pub-assets.aurm.in/convenience_logo.webp" loading="lazy">
                <source src="https://pub-assets.aurm.in/videos/Feature_Animation_02.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
                <div className="card_body">
                  <div className="card_header">Unparalleled Convenience</div>
                  <div className="card_sub_text">
                    Available in your gated community, and accessible whenever
                    you need it, 24/7/365.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 feature_card">
              <div className="card text-center bg-transparent border-0">
              <video className="card-img-top" muted autoPlay loop playsInline poster="https://pub-assets.aurm.in/delight_logo.webp" loading="lazy">
                <source src="https://pub-assets.aurm.in/videos/Feature_Animation_03.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
                <div className="card_body">
                  <div className="card_header">A Delightful Experience</div>
                  <div className="card_sub_text">
                    A premium dressing room ambience to access and enjoy your
                    most prized possessions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="features_know_more_button_div">
          <a href="https://api.whatsapp.com/send/?phone=919740741016&text=I'm intrigued about Aurm. Tell me more! &type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
            <button className="features_know_more_btn">Know more</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FeaturesComponent;
