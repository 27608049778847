import React, { useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import "../assets/css/testimonial.css";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import video_play_button from "../assets/images/play_button.svg";
import cross_icon from "../assets/images/cross_icon.svg";

/**
    Shows the Testimonial section
*/
function TestimonialComponent() {
  const [videoSource, setVideoSource] = useState(null);
  const [testimonialData, setTestimonialData] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const watchVideoRef = useRef(null);
  const modalRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://pub-assets-microsite.aurm.in/testimonials%2Faprvillas%2Ftestimonial_v1.json');
        const data = await response.json();
        setTestimonialData(data);
      } catch (error) {
        console.error("Error fetching testimonial data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const watchVideoHandler = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!videoPlaying && watchVideoRef.current) {
            if (watchVideoRef.current.paused) {
              watchVideoRef.current
                .play()
                .then(() => {
                  setVideoPlaying(true);
                })
                .catch((error) => {
                  console.error("Error playing video:", error);
                });
            }
          }
        } else {
          if (videoPlaying && watchVideoRef.current) {
            if (!watchVideoRef.current.paused) {
              try {
                watchVideoRef.current.pause();
              } catch (error) {
                console.error("Error pausing video:", error);
              }
              setVideoPlaying(false);
            }
          }
        }
      });
    };

    const observer = new IntersectionObserver(watchVideoHandler, options);
    const section = document.getElementById("watch_video");
    if (section) {
      observer.observe(section);
    }
    return () => {
      observer.disconnect();
    };
  }, [videoPlaying]);
  useEffect(() => {
    // Handle body class for blur effect
    const sectionSelectors = ['header', '#watch_video_container', '#landing_section', '#feature_section', '#team_section', '#blogs_list_section',
       '#partner_with_us_section', '#gallery_section', '#testimonial_section', '#reach_us_section', '#contact_section', '#faqs_main_section', '#footer_section', '#our_story_section_container'];

    // Handle class for blur effect on each section
    sectionSelectors.forEach((selector) => {
      const sectionElement = document.querySelector(selector);
      if (sectionElement) {
        if (modalOpen) {
          sectionElement.classList.add('blur-background');
        } else {
          sectionElement.classList.remove('blur-background');
        }
      }
    });
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
    const modal = Modal.getInstance(modalRef.current);
    if (modal) {
      modal.hide();
      setVideoPlaying(false);
      // Pause the video when the modal is closed
      if (watchVideoRef.current && !watchVideoRef.current.paused) {
        watchVideoRef.current.pause();
      }
      if (watchVideoRef.current) {
        watchVideoRef.current.currentTime = 0;
      }
    }
  };

  const openVideoModal = () => {
    setModalOpen(true);
    const modal = new Modal(modalRef.current, {
      backdrop: "static",
      keyboard: false,
    });
    modal.show();
  };
    

  const handlePlayButtonClick = (video_url) => {
    if (video_url){
      setVideoSource(video_url);
    }
    // Open the modal
    openVideoModal();
    if (watchVideoRef.current) {
      watchVideoRef.current.load();
      watchVideoRef.current.play()
        .then(() => {
          setVideoPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    }
  }

  const testimonialItems = testimonialData.flatMap((group) =>
  group.testimonials.map((testimonial) => ({
    testimonialDescription: testimonial.testimonialDescription,
    personName: testimonial.personName,
    personDescription: testimonial.personDescription,
    image: testimonial.image,
    is_video: testimonial.is_video,
    video_url: testimonial.video_url,
    web_view_thumbnail: testimonial.web_view_thumbnail,
    mobile_view_thumbnail: testimonial.mobile_view_thumbnail
  }))


);

  return (
    <div id="testimonial_main_section">
      <div id="testimonial_section" className="container">
        <div className="container testimonial_content_resident">
          <span className="testimonial_what_text">What</span>{" "}
          <span className="testimonial_resident_text">
            our members
          </span>{" "}
          <span className="testimonial_what_text">are saying</span>
        </div>
        <div className="desktop-carousel">
          <Carousel>
            {testimonialData.map((item, index) => (
              <Carousel.Item key={index}>
                <div className="testimonial-card">
                  {item.testimonials.map((testimonial, testimonialIndex) => (
                    <div
                      className="testimonial_content_container"
                      key={testimonialIndex}
                      // style={{ padding: testimonial.is_video && testimonial.video_url ? '0' : 'px' }}
                    >
                      {testimonial.is_video && testimonial.video_url ? (
                        // If it's a video, render the video component
                        <div className="testimonial_video_container" id="testimonial_video">
                          <img width="100%" height="100%" src={testimonial.web_view_thumbnail} className="testimonial_vd_poster" alt="ajs" loading="lazy" />
                          <img src={video_play_button} className='testimonial_video_play_button' onClick={() => handlePlayButtonClick(testimonial.video_url)} alt="play_btn" loading="lazy" />
                        </div>
                      ) : (
                        // If it's not a video, render text
                        <div className="testimonial_text_container" id="text_container">
                          <div className="testimonial_primary_text text-white text-wrap">
                            {testimonial.testimonialDescription}
                          </div>
                          <div className='d-flex mt-3'>
                            {/* <div className='testimonial_person_img mt-3' >
                                <img src={testimonial.image} alt="testimonial_Publisher" className="img_styles"/>
                            </div> */}
                            {/* <div className='testimonial_person_img mt-3'>
                                {testimonial.image && (
                                    <img src={testimonial.image} alt="testimonial_Publisher" className="img_styles" />
                                )}
                            </div> */}
                            <div className='testimonial_person_name mt-3'>
                              <span className='testimonial_person_name'>{testimonial.personName}</span><br /><span className='testimonial_person_description text-white'>{testimonial.personDescription}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        {/* Mobile version (below 430 pixels) */}
        <div className="mobile-carousel">
          <Carousel>
            {testimonialItems.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <div className="testimonial-card">
                    <div
                      className="testimonial_content_container"
                      key={index}
                      style={{ 
                        width: '94%',
                        padding: testimonial.is_video && testimonial.video_url ? '0' : '22px',
                      }}
                    >
                      {testimonial.is_video && testimonial.video_url ? (
                        // If it's a video, render the video component
                        <div className="testimonial_video_container">
                          <img width="100%" height="100%" src={testimonial.mobile_view_thumbnail} className="testimonial_vd_poster" alt="ajs" loading="lazy" />
                          <img src={video_play_button} className='testimonial_video_play_button' onClick={() => handlePlayButtonClick(testimonial.video_url)} alt="play_btn" loading="lazy"/>
                        </div>
                      ) : (
                        // If it's not a video, render text
                        <div className="testimonial_text_container">
                          <div className="testimonial_primary_text text-white text-wrap">
                            {testimonial.testimonialDescription}
                          </div>
                          <div className='d-flex mt-3 testimonial_person_details'>
                            {/* <div className='testimonial_person_img mt-3' >
                                <img src={testimonial.image} alt="testimonial_Publisher" className="img_styles"/>
                            </div> */}
                            {/* <div className='testimonial_person_img mt-3'>
                                {testimonial.image && (
                                    <img src={testimonial.image} alt="testimonial_Publisher" className="img_styles" />
                                )}
                            </div> */}
                            <div className='testimonial_person_name mt-3'>
                                <span className='testimonial_person_name'>{testimonial.personName}</span><br /><span className='testimonial_person_description text-white'>{testimonial.personDescription}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="modal fade" ref={modalRef}>
          <div className="modal-dialog modal-xl watch-video-modal-class modal-dialog-centered bg-transparent video_model">
            <div className="modal-content video_modal_content">
              <div className="modal-body bg-none">
                <div id="video_close_button">
                  <div
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 40,
                      height: 40,
                      left: 0,
                      top: 0,
                      background: "rgba(255, 255, 255, 0.20)",
                      borderRadius: 9999,
                    }}
                  >
                    <img src={cross_icon} alt="" loading="lazy"/>
                  </div>
                </div>

                <video
                  width="100%"
                  height="100%"
                  controls
                  preload="auto"
                  onPause={() => setVideoPlaying(false)}
                  onEnded={() => setVideoPlaying(false)}
                  ref={watchVideoRef}
                  loading="lazy"
                >
                  <source
                    src={videoSource}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
      </div>
  </div>
  );
}

export default TestimonialComponent;