import React, { useEffect } from "react";
import Header from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import "../assets/css/terms_and_conditions.css";
import "../assets/css/global_styles.css";
import TermsKeyHighlightsComponent from "./TermsKeyHighlightsComponent";

/**
  Shows Terms and Conditons page
*/
function TermsAndConditionsComponent() {
  useEffect(() => {
    const scroll_to = document.getElementById("policy_section");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <section id="terms_section">
      <Header />
      <div id="policy_section">
        <div className="container">
          <div className="row">
            <div className="container" id="padding_privacy_id">
              <div id="policy_header"></div>
              <div className="container" id="privacy_and_refund_container">
                <div
                  className="text-center px-2 policy_div"
                  id="privacy_policy_head"
                >
                  <div id="privacy_policy_main_head" className="terms_head">
                    Terms and Conditions
                  </div>
                  <div id="policy_intro">
                  This is a summary of the key aspects of the Safe Deposit Agreement. After the KYC process, the detailed agreement will be presented for your signature
                  </div>
                </div>
              </div>
              <div id="privacy_container_intro">
                <div className="text-left policy_div">
                  <TermsKeyHighlightsComponent />
                  <div
                    className="policy_section_sub_heads"
                    id="privacy_details_info"
                  >
                    1.Definitions:
                  </div>
                  <div className="policy_information">
                    1.1 In these “Terms and Conditions”, the following
                    definitions apply:
                  </div>
                  <div className="policy_information">
                    a. Safe Deposit Locker Agreement: These conditions, the
                    Agreement, and the Schedule forming part of the Agreement. 
                  </div>
                  <div className="policy_information">
                    b. Locker: The safe deposit locker licensed by the Company
                    to the Customer pursuant to this agreement. 
                  </div>
                  <div className="policy_information">
                    c. Customer: Any natural person identified as the Customer
                    in the Safe Deposit Locker Agreement.
                  </div>
                  <div className="policy_information">
                    d. Deposit: A one-time sum of money collected at the start
                    of the term for specific purposes and is refundable as set
                    out in the Agreement and these conditions, and is set out in
                    the Safe Deposit Locker Agreement and related Annexures. 
                  </div>
                  <div className="policy_information">
                    e. Consideration: The amount charged by Us for the license
                    to use the the locker as set out in the Agreement.
                  </div>
                  <div className="policy_information">
                    f. Nominee: A person nominated by you who is granted the
                    right of access to the locker, in case of death of the
                    Customer. 
                  </div>
                  <div className="policy_information">
                    g. Notice: Written communication by either Us or You served
                    in accordance with the Agreement and the conditions. 
                  </div>
                  <div className="policy_information">
                    h. Privacy Policy: Privacy policy of the Company available
                    on the website of the Company.
                  </div>
                  <div className="policy_information">
                    i. Start Date: The start date as stated in the Agreeement.
                  </div>
                  <div className="policy_information">
                    j. Term: The period of 5 years or such additional period as
                    set out in the Agreement, commencing on the start date, as
                    extended from time to time in accordance with this
                    agreement. 
                  </div>
                  <div className="policy_information">
                    k. ‘We’, ‘Us’, ‘Our’, ‘The Company’, ‘Aurm’ viz., Pransu
                    Technologies Private Limited.
                  </div>
                  <div className="policy_information">
                    l. ‘You’, ‘Your’: The Customer named in the Agreement. 
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    2.Deposit & Consideration:
                  </div>
                  <div className="policy_information">
                    2.1 The Deposit must be paid by You on or before the Start
                    Date. Subject to the provisions of these Conditions, the
                    Deposit is refundable upon termination or expiry of the
                    Lease Agreement, provided You return all debts owed to Us
                    under the Safe Deposit Locker Agreement have been paid in
                    full.
                  </div>
                  <div className="policy_information">
                    2.2 All other charges that may be payable by You at any
                    time, whether in relation to additional services or any
                    other charges which You may from time to time incur, are as
                    set out in Our Price List from time to time. We reserve the
                    right to amend the Price List from time to time and without
                    Notice.
                  </div>
                  <div className="policy_information">
                    2.3 All payments may be made by credit, debit, or online
                    transfer. We will not treat any amount as paid until We are
                    in receipt of cleared funds for the total amount owed. We
                    will issue You with an invoice detailing the license of the
                    Locker and all related services agreed and the amount due to
                    Us.
                  </div>
                  <div className="policy_information">
                    2.4 All our prices and charges are quoted in Indian Rupees,
                    and unless otherwise stated, are exclusive of applicable
                    taxes at the prevailing rate and where applicable.
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    3.Late Payment, Non-Payment, and Interest
                  </div>
                  <div className="policy_information">
                    3.1 Rent and all other charges and/or other payments due
                    under this Lease Agreement are to be paid within the period
                    stated in the Safe Deposit Locker Agreement, these
                    Conditions and/ or the Schedule (as the case may be) (“Due
                    Date”).
                  </div>
                  <div className="policy_information">
                    3.2 In the event You fail to pay any sums due to Us at any
                    time by the due date, interest shall be charged by Us on the
                    outstanding amount at the rate of 3% (Three percent) p.m.
                    from time to time and from the date the payment became due
                    until the outstanding sums are paid in full. This right is
                    in addition to and without prejudice to any other rights We
                    may have in relation to late payment.
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    4.Suspension of services
                  </div>
                  <div className="policy_information">
                    4.1 In the event of non-payment by the Due Date for payment
                    or any other charges, We reserve the right to suspend the
                    lease of the Locker without further Notice.
                  </div>
                  <div className="policy_information">
                    4.2 Pursuant to paragraph above:
                  </div>
                  <div className="policy_information">
                    a. We will not allow You access to the Locker; and
                  </div>
                  <div className="policy_information">
                    b. All Our liability to You in respect of the contents of
                    the Locker shall cease, until such time as all outstanding
                    amounts shall have been paid to Us in full together with
                    interest.
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    5.Registration of Biometrics & Face ID:
                  </div>
                  <div className="policy_information">
                    5.1 When You apply for a Locker with Us, You i.e. the
                    primary account holder and joint holder may be registered
                    with their respective biometrics and face IDs so that the
                    access is restricted to the registered biometrics and face
                    recognitions of the primary holder and joint holder for the
                    respective Locker(s) only.When You apply for a Locker with
                    Us, You i.e. the primary account holder and joint holder may
                    be registered with their respective biometrics and face IDs
                    so that the access is restricted to the registered
                    biometrics and face recognitions of the primary holder and
                    joint holder for the respective Locker(s) only.
                  </div>
                  <div className="policy_information">
                    5.2. You hereby confirm that You consent to Your personal
                    data being collected, used, processed and held by Us in its
                    relevant manual and automated filing systems.{" "}
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    6.Contents of the Locker & the Operation of the Locker{" "}
                  </div>
                  <div className="policy_information">
                    6.1. License to use the Locker is:
                  </div>
                  <div className="policy_information">
                    a. personal and for Your use (viz., You and Your spouse’s,
                    children’s, siblings, grandparents and in-law’s)
                  </div>
                  <div className="policy_information">b. non- transferable</div>
                  <div className="policy_information">
                    c. only for legitimate purposes such as storing of valuables
                    like jewellery and documents
                  </div>
                  <div className="policy_information">
                    d. not for storing (i) arms, weapons, explosives, drugs and/
                    or any contraband material; and/ or (ii) any perishable
                    material and/ or radioactive material and/or any illegal
                    substance; and/or (iii) any cash or currency; and/ or (iv)
                    any material which can create any hazard or nuisance to the
                    Company or to any of its customers; and/ or (v) any
                    chemicals, plants or plant materials, nor any living
                    organism or any other substance or material which may be the
                    subject of any ban, embargo or import restriction; and/ or
                    (vi) any material or item restricted or illegal to be stored
                    in the Locker under applicable laws.
                  </div>
                  <div className="policy_information">
                    6.2. You shall (i) use the Locker only for the purpose for
                    which it is provided and in accordance with applicable law
                    and regulations (ii) abide by rules and regulations for
                    Locker operation as We may specify from time to time adopt
                    (iii) install Your own lock for the purposes of locking the
                    Locker and keep in possession the keys to the Locker (iv)
                    keep the key or password or any other identification or
                    entry mechanism provided by Us for opening of the Locker in
                    a place of safety, not share the same with any other person
                    and not allow the same to fall into hands of any other
                    person, so as to save unauthorized use of the Locker; (v)
                    operate the Locker only using the key, password or any other
                    identification mechanism and not otherwise (vi) immediately
                    inform Us forthwith in case of loss of the key, password or
                    any other identification mechanism provided for the
                    operation of the Locker.
                  </div>
                  <div className="policy_information">
                    6.3. You shall have no right or property on the Locker
                    itself other than the right to access the contents and use
                    the Locker in accordance with the terms and conditions
                    specified under the Agreement.
                  </div>
                  <div className="policy_information">
                    6.4. You acknowledge that We are not aware of the contents
                    of the Locker and have no knowledge as to the purpose of the
                    storage or the use to which the contents of the Locker may
                    be put at any time.
                  </div>
                  <div className="policy_information">
                    6.5. You shall be allowed to operate the Locker at any point
                    in time and any day of theweek i.e., on a 24/7 basis. This
                    access is granted without any time restrictions, allowing
                    You the freedom to retrieve or deposit items at Your
                    convenience, provided the facility is not already in
                    operation by another Customer. However, in the event We are
                    not able to operate for any reason beyond our control such
                    as planned or unplanned maintenance, power outage, flood,
                    riot, curfew, lockout or any other reason etc., We shall not
                    have any obligation to allow operation of Locker.
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    7.Term, Termination & Refund:
                  </div>
                  <div className="policy_information">
                    7.1. The Safe Deposit Locker Agreement shall commence on the
                    Execution Date and, unless sooner terminated, remain in
                    effect for 5 (Five) years following the Execution Date. The
                    Agreement will automatically be renewed for an additional
                    period of 5 (Five) years without any further actions by
                    either Party after the expiry of the Term.
                  </div>
                  <div className="policy_information">
                    a. all outstanding amounts due (including Consideration) to
                    Us and remaining outstanding, must be paid immediately;
                  </div>
                  <div className="policy_information">
                    b. You must immediately remove all contents from the Locker.
                  </div>
                  <div className="policy_information">
                    7.3. We may terminate the Lease Agreement:
                  </div>
                  <div className="policy_information">
                    a. With immediate effect, by way of written Notice to You if
                    We become aware of or reasonably suspect any breach of the
                    terms terms of the Safe Deposit Locker Agreement.{" "}
                  </div>
                  <div className="policy_information">
                    b. in the event of non-payment of Consideration on the due
                    date
                  </div>
                  <div className="policy_information">
                    c. with immediate effect in the event that a petition of
                    bankruptcy/ insolvency is presented against You or similar
                    proceedings are taken.
                  </div>
                  <div className="policy_information">
                    d. With immediate effect, by way of written notice to You in
                    the event You cease to stay in the apartment complex in
                    which the Locker is situated
                  </div>
                  <div className="policy_information">
                    e. With immediate effect, by way of written notice to You,
                    pursuant to order from a court of competent jurisdiction or
                    any governmental or regulatory authority;{" "}
                  </div>
                  <div className="policy_information">
                    f. in the event of Your breach of or default under this
                    Agreement and/ or We being of the view that You are not
                    co-operating and/or complying with the terms and conditions
                    of the Agreement
                  </div>
                  <div className="policy_information">
                    7.4. We may terminate this Agreement by providing You with a
                    prior written notice of 1(One) month. In the event We are
                    terminating the agreement, You shall forthwith and before
                    the end of the notice period stipulated under the written
                    notice surrender and vacate the Locker.
                  </div>
                  <div className="policy_information">
                    7.5. You may terminate this with a prior notice and after
                    the expiry of the Lock-In Period as per the Plan opted by
                    You. In the event You are terminating the agreement, You
                    shall forthwith vacate the Locker.
                  </div>
                  <div className="policy_information">
                    7.6. The Consideration is payable in advance at the
                    beginning of the year. Once the Consideration is paid by You
                    thereafter no refund will be allowed during the Lock-in
                    Period, based on the Plan opted by You. After the expiry of
                    the Lock-in Period, in the event that You decide to
                    terminate the Agreement for the assigned Locker before the
                    expiration of the Term, a pro-rated refund of the
                    Consideration will be issued. The refund amount will be
                    calculated based on the remaining unused portion of the
                    prepaid component of the Consideration, excluding any
                    applicable fees or charges. You acknowledge and agree that
                    the pro-rated refund will be processed within 15 business
                    days from the date of the request for termination is
                    processed by Us, and any outstanding fees or charges will be
                    deducted from the refund amount.
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    8.Limitation of Liability:
                  </div>
                  <div className="policy_information">
                    8.1. While We will exercise all such normal precautions as
                    it may deem fit, We will not be responsible for any loss or
                    deterioration of or damage or loss to articles in locker
                    whether caused by act that is attributable to the fault or
                    negligence from You whatsoever or rain, fire, flood,
                    earthquake, lightening, act of war, civil disorder,
                    terrorist attack or any other similar cause(s) beyond the
                    reasonable control of Us and/ or force majeure events
                  </div>
                  <div className="policy_information">
                    8.2. Notwithstanding any other provision of the Safe Deposit
                    Locker Agreement, in the event of loss of contents of Locker
                    are due to incidents of Our gross negligence and by any act
                    of omission /commission or attributable to fraud committed
                    by Our employee(s), then our liability shall be the lower
                    of: (i) an amount equivalent to the insurance cover on the
                    Locker as per the Plan opted by You, or (ii) invoice value
                    or valuation report of the contents lost.
                  </div>
                  <div className="policy_information">
                    8.3. The contents of the Locker shall be insured by Us with
                    a reputed Insurance Company, against the below mentioned
                    risks for an amount, as per the Plan opted by You:
                  </div>
                  <div className="policy_information">
                    a. Loss or damage to the contents stored in the Locker in
                    the event of Fire and allied perils, Earthquake (EQ), Storm,
                    Tempest, Flood and Inundation (STFI) other Acts of God (AOG)
                    perils, theft, burglary, holdup, Fidelity, Riot, Strike and
                    Malicious Damage (RSMD) or any act of terrorism.
                  </div>
                  <div className="policy_information">
                    b. For the purpose this insurance, contents mean any
                    valuable items including precious stones/precious
                    metals/Deeds, Manuscripts and business books, plans,
                    drawings, securities, obligations or documents of any kind,
                    but only for the cost of the materials and clerical labour
                    expended in reproducing such records and other important
                    valuable documents only. Any nature of Currency notes are
                    excluded from the scope of the policy cover.
                  </div>
                  <div className="policy_information">
                    8.4. To be admissible under the Insurance Policy, purchase
                    bills or valuation report from a registered valuer should be
                    procured by You for all kind of valuable items including
                    precious metals/stones/jewellery before placing it inside
                    the Locker and the same should be submitted to the Insurance
                    Company, in the event of claim. In case of loss, damage or
                    disappearance of Deeds, Manuscripts and business books,
                    plans, drawings, securities, obligations or documents of any
                    kind the coverage is limited to the reasonable cost of
                    replacing the lost or damaged documents, but only for the
                    value of materials as stationery together with cost of
                    clerical labour expended in preparing the documents.
                    However, if the documents are issued by any statutory body
                    or any other competent authority, then in that case the
                    basis of settlement will be the cost of replacing the
                    damaged documents inclusive of application money, fees and
                    stamps as statutorily required as well as cost of
                    professional accountant, architect or lawyer utilized for
                    the purpose of replacing or recreation of documents but
                    excluding any transportation cost and time delays.
                  </div>
                  <div className="policy_information">
                    8.5. Further, the claim under the Insurance Policy will be
                    admissible only if You lodge a FIR with the Police and a
                    Closure Report is issued by the Police for the filed FIR.
                  </div>
                  <div className="policy_information">
                    8.6. Insurance Cover will not be applicable in case of the
                    following instances:
                  </div>
                  <div className="policy_information">
                    a. Any items which are not stored within designated locker.
                  </div>
                  <div className="policy_information">
                    b. Any claim in respect of unexplained loss or mysterious
                    disappearance from the locker.
                  </div>
                  <div className="policy_information">
                    c. Any claim for loss of currency or cash.
                  </div>
                  <div className="policy_information">
                    d. Deductible of 10% of each claim subject to a minimum of
                    Rs 5,000 for Burglary, 12% of each claim subject to a
                    minimum of Rs 10,000 for Theft, 15% of each claim subject to
                    a minimum of Rs 10,000 for Fidelity and 5% of each claim
                    subject to a minimum of Rs 10,000 for other risks
                  </div>
                  <div className="policy_section_sub_heads policy_sub_section_btm_pd">
                    9.General:
                  </div>
                  <div className="policy_information">
                    9.1. You acknowledge that Your rights regarding the Locker
                    are those of a Licensee and nothing in the Conditions grants
                    You any right to ownership in the Locker or the premises in
                    which it is stored or otherwise kept. You must neither
                    assign, sublet nor transfer any interest in the locker or
                    any part of it.
                  </div>
                  <div className="policy_information">
                    9.2. The Parties acknowledge and agree that this Agreement
                    may be executed and delivered by electronic signature, which
                    shall be considered as an original signature for all
                    purposes and shall have the same legal validity and
                    enforceability as a manually executed signature, and the
                    Parties hereby waive any objection to the contrary.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="privacy_footer" id="privacy_footer">
          <FooterComponent />
        </div>
      </div>
    </section>
  );
}
export default TermsAndConditionsComponent;
