import React, { useEffect } from "react";
import "../assets/css/global_styles.css";
import "../assets/css/key_highlights.css";

/**
  Key Highlights Component
*/
function KeyHighlightsComponent() {
  useEffect(() => {
    const scroll_to = document.getElementById("blogs_page_div");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <section id="key_highlights_section">
      <div className="container p-0">
        <div id="key_header">
          Key Highlights
        </div>
        <div className="row kh_row">
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white mob_first_row_card">
              <div className="card_custom_header">What we ask for</div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  For KYC, a mandatory requirement for Aurm, we ask for your
                  personal information such as your official identity, address
                  proof and photograph. On our app, we ask for your location and
                  app usage information.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white mob_first_row_card">
              <div className="card_custom_header">What we use it for</div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  We use the data for account opening, identity verification,
                  providing updates, marketing communications, personalising and
                  improving our content and services.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white">
              <div className="card_custom_header">Your data is secure</div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  Strong security measures are in place to protect your data.
                  Aurm complies with all data privacy and protection laws
                  including the Digital Personal Data Protection (DPDP) Act
                  2023. We are also ISO 9001:2015 and ISO 27001:2022 compliant.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white tab_cs">
              <div className="card_custom_header">What we share</div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  Some features and privileges that we offer to you will be in
                  association with other commercial partners and affiliates, and
                  we might share the data with them to facilitate our operations
                  or comply with legal obligations
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white">
              <div className="card_custom_header">Your consent</div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  By using Aurm’s services, you consent to the collection and
                  use of your information. We may contact you for
                  service-related and marketing communications.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 kh_card_div">
            <div className="card kh_card bg-transparent border-0 text-white">
              <div className="card_custom_header">If there are changes</div>
              <div className="card_custom_body">
                <div className="card_custom_text">
                  The privacy policy may be updated, and will be posted on our
                  website before the changes come into effect. You can review
                  the <span>
                    <a
                      href="#privacy_details_info"
                      className="text-decoration-underline light_red_color"
                    >
                      privacy policy
                    </a>
                  </span> periodically.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeyHighlightsComponent;
